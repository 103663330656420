import { StateFromZip } from "@/shared/hooks/useGiftCardZipInfo";
import useGiftCardZipInfo from "@/shared/hooks/useGiftCardZipInfo";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGift } from "@fortawesome/free-solid-svg-icons";
import { cn } from "../utils";

interface GiftcardFieldWrapperProps {
    children: React.ReactNode;
    stateFromZip: StateFromZip | null | undefined;
    showGiftCardWrapper: boolean;
    cssWrapper?: string;
}

export default function GiftCardFieldWrapper(props: GiftcardFieldWrapperProps) {
    const { children, stateFromZip, showGiftCardWrapper, cssWrapper } = props;

    let content = children;
    const { bgColor: giftCardBackgroundColor, text: giftCardText, iconColor: giftCardIconColor } = useGiftCardZipInfo(stateFromZip || undefined);

    if (showGiftCardWrapper) {
        content = (
            <div className={cn("-m-2 space-y-1 rounded-lg p-2 pb-1", cssWrapper, giftCardBackgroundColor)}>
                {children}
                <span className="flex items-center justify-center gap-1 text-xs leading-5 text-content-primary">
                    <FontAwesomeIcon icon={faGift} className={giftCardIconColor} />
                    {giftCardText}
                </span>
            </div>
        );
    }

    return <>{content}</>;
}
