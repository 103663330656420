// packages
import React, { useEffect, useState, useMemo } from "react";
import { useMaskito } from "@maskito/react";
import { useFormContext, Controller } from "react-hook-form";

// hooks
import { useAppLayerContext } from "@/shared/contexts/AppLayer";
import { useStateFromZipcode } from "@/shared/hooks/useRatingAddress";
import { useMemberCenterStatus } from "../hooks/useMemberCenterStatus";

// utils
import Strings from "@/shared/utils/Strings.constants";
import { UIUtils } from "@/shared/utils/UIUtils";
import { PublicConfig } from "@/shared/PublicConfig";

// components
import { FormField } from "@/shared/components/FormField";
import { InputWithLabel } from "@/shared/components/ui/InputWithLabel";
import GiftCardFieldWrapper from "@/shared/components/GiftCardFieldWrapper";
import MemberCenterModal from "@/shared/components/MemberCenterModal";
import { cn } from "../utils";

// types
import { ErrorIdType } from "@/shared/types/SpotAPI";

interface UserContactConfigProps {
    config: {
        showFirstName?: boolean;
        showLastName?: boolean;
        showMobilePhone?: boolean;
        email: string | undefined;
        underwriter: "ptz-us" | "ptz-ca";
        zipField: {
            label: string;
            placeholder?: string;
            mask?: { options: { mask: any } };
            showGiftCardWrapper: boolean;
        };
    };
    styles?: {
        wrapper?: string;
        fields?: {
            firstName?: string;
            lastName?: string;
            phone?: string;
            email?: string;
            zipCode?: string;
        };
    };
}

export function UserContactEditor(props: UserContactConfigProps) {
    const { showFirstName = false, showLastName = false, showMobilePhone = false, zipField, underwriter, email } = props.config;
    const { styles } = props;

    const {
        control,
        setValue,
        setError,
        clearErrors,
        watch,
        setFocus,
        formState: { errors, submitCount }
    } = useFormContext();

    const { appState, updateAppState } = useAppLayerContext();
    const { asyncErrors } = appState;
    const [showModal, setShowModal] = useState(false);

    const currentRatingZipcode = watch(`ratingZipcode`);
    const { stateFromZip } = useStateFromZipcode(currentRatingZipcode);
    const { isVerified } = useMemberCenterStatus({ email: email, underwriter: underwriter });

    const zipMask = useMaskito(zipField?.mask);
    const phoneMask = useMaskito(UIUtils.PhoneMask);

    const allUserContactErrorsMap: Partial<Record<ErrorIdType, { path: string; message: string; showModal?: boolean }>> = useMemo(
        () => ({
            "invalid-postal-code": { path: "ratingZipcode", message: "Invalid postal code" },
            "location-not-available": { path: "ratingZipcode", message: "We're sorry, Spot is not available in your area" },
            "invalid-phone-number": { path: "phone", message: "Invalid phone number" },
            "account-exists": { path: "email", message: "This email is already registered", showModal: true }
        }),
        []
    );

    useEffect(() => {
        if (!!asyncErrors && asyncErrors?.length > 0 && submitCount > 0) {
            clearErrors();

            let handledAllErrors = true;

            asyncErrors.forEach(error => {
                const errorMapping = allUserContactErrorsMap[error.id];
                if (!!errorMapping) {
                    if (errorMapping.showModal) {
                        setShowModal(true);
                    }
                    setError(errorMapping.path, { message: errorMapping.message });
                    setFocus(errorMapping.path);
                } else {
                    handledAllErrors = false;
                }
            });

            if (!handledAllErrors) {
                updateAppState({ hasUnknownError: true });
            }
        }
    }, [asyncErrors, setError, setFocus, submitCount, clearErrors, allUserContactErrorsMap, updateAppState]);

    useEffect(() => {
        if (isVerified && PublicConfig.ENVIRONMENT === `production`) {
            setError(`email`, { message: `This email is already registered` });
        }
    }, [isVerified, setError, submitCount]);

    return (
        <div className={cn("flex flex-col gap-6", styles?.wrapper)}>
            <Controller
                name="ratingZipcode"
                control={control}
                render={({ field: { ref, ...rest }, fieldState }) => {
                    return (
                        <GiftCardFieldWrapper stateFromZip={stateFromZip} showGiftCardWrapper={zipField.showGiftCardWrapper} cssWrapper={styles?.fields?.zipCode}>
                            <FormField
                                className={cn("flex-1", !zipField.showGiftCardWrapper && styles?.fields?.zipCode)}
                                error={fieldState?.error?.message}
                                errorId="error-ratingZipcode"
                            >
                                <InputWithLabel
                                    label={zipField?.label || Strings.ZIP_CODE}
                                    inputRef={el => UIUtils.chainRefs(el, zipMask, ref)}
                                    error={fieldState?.error?.message}
                                    inputProps={{
                                        ...rest,
                                        autoComplete: "postal-code",
                                        "aria-describedby": errors?.ratingZipcode ? "error-ratingZipcode" : undefined,
                                        placeholder: zipField?.placeholder,
                                        onBlur: event => {
                                            rest.onBlur();
                                            const hasAsyncError = asyncErrors?.some(error => error.id === `invalid-postal-code` || error.id === `location-not-available`);
                                            if (hasAsyncError) {
                                                updateAppState({
                                                    asyncErrors: asyncErrors?.filter(error => error.id !== `invalid-postal-code` && error.id !== `location-not-available`)
                                                });
                                            }
                                        },
                                        onInput: event => {
                                            const current = event.currentTarget.value;
                                            rest.onChange(current);
                                            setValue("ratingZipcode", current);
                                        }
                                    }}
                                />
                            </FormField>
                        </GiftCardFieldWrapper>
                    );
                }}
            />

            <Controller
                name="email"
                control={control}
                render={({ field: { ref, ...rest }, fieldState }) => (
                    <FormField className={cn("flex-1", styles?.fields?.email)} error={fieldState?.error?.message} errorId="error-email">
                        <InputWithLabel
                            label={Strings.EMAIL_ADDRESS}
                            inputRef={ref}
                            error={fieldState?.error?.message}
                            inputProps={{
                                ...rest,
                                placeholder: "my@email.com",
                                type: "email",
                                autoComplete: "email",
                                "aria-describedby": errors?.email ? "error-email" : undefined
                            }}
                        />
                    </FormField>
                )}
            />

            {showModal && <MemberCenterModal open={showModal} onClose={() => setShowModal(false)} />}

            {showFirstName && (
                <Controller
                    name="firstName"
                    control={control}
                    render={({ field: { ref, ...rest }, fieldState }) => (
                        <FormField className={cn("flex-1", styles?.fields?.firstName)} error={fieldState?.error?.message} errorId="error-firstName">
                            <InputWithLabel
                                label={Strings.FIRST_NAME}
                                inputRef={ref}
                                error={fieldState?.error?.message}
                                inputProps={{
                                    ...rest,
                                    autoComplete: "given-name",
                                    "aria-describedby": errors?.firstName ? "error-firstName" : undefined
                                }}
                            />
                        </FormField>
                    )}
                />
            )}
            {showLastName && (
                <Controller
                    name="lastName"
                    control={control}
                    render={({ field: { ref, ...rest }, fieldState }) => (
                        <FormField className={cn("flex-1", styles?.fields?.lastName)} error={fieldState?.error?.message} errorId="error-lastName">
                            <InputWithLabel
                                label={Strings.LAST_NAME}
                                inputRef={ref}
                                error={fieldState?.error?.message}
                                inputProps={{
                                    ...rest,
                                    autoComplete: "family-name",
                                    "aria-describedby": errors?.lastName ? "error-lastName" : undefined
                                }}
                            />
                        </FormField>
                    )}
                />
            )}
            {showMobilePhone && (
                <Controller
                    name="phone"
                    control={control}
                    render={({ field: { ref, ...rest }, fieldState }) => (
                        <FormField className={cn("flex-1", styles?.fields?.phone)} error={fieldState?.error?.message} errorId="error-phone">
                            <InputWithLabel
                                label="Mobile number (optional)"
                                inputRef={el => UIUtils.chainRefs(el, phoneMask, ref)}
                                error={fieldState?.error?.message}
                                inputProps={{
                                    ...rest,
                                    autoComplete: "tel-national",
                                    placeholder: "(555) 555-5555",
                                    type: "tel",
                                    "aria-describedby": errors?.phone ? "error-phone" : undefined,
                                    onInput: event => {
                                        rest.onChange(event.currentTarget.value);
                                        setValue(`phone`, event.currentTarget.value);
                                    },
                                    onBlur: event => {
                                        rest.onBlur();
                                        const hasAsyncError = asyncErrors?.some(error => error.id === `invalid-phone-number`);
                                        if (hasAsyncError) {
                                            updateAppState({ asyncErrors: asyncErrors?.filter(error => error.id !== `invalid-phone-number`) });
                                        }
                                    }
                                }}
                            />
                        </FormField>
                    )}
                />
            )}
        </div>
    );
}
